import React from 'react';
import { useFormContext } from 'react-hook-form';
import { listItem } from '../banners/DisplayItems';
import InputFieldControl from '../base/InputFieldControl';
import { LanguageConfig } from '../../config/CustomEnums';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';

export default function CarparkGeneralSection({ language }){
  const isEnglish = language === LanguageConfig.english;
  const { getValues, watch, setValue } = useFormContext();
  const carparkName = isEnglish ? getValues('name') : getValues(`translations.${language}.name`);
  const watchChargerLocation = isEnglish ? watch('chargerLocation') : watch(`translations.${language}.chargerLocation`);
  const watchShowHourlyChargesUrl =  watch('showHourlyChargesUrl');
  const watchHourlyChargesUrl = isEnglish ? watch('hourlyChargesUrl') : watch(`translations.${language}.hourlyChargesUrl`);

  return(
    <>
      <label className="create-section-title">{'General'}</label>
      { listItem( 'CarPark', carparkName )}
      <InputFieldControl
        name={isEnglish ? 'chargerLocation' : `translations.${language}.chargerLocation`}
        title={'Charger Location'}
        rules={{}}
        value={watchChargerLocation}
        setValue={(value) => {
          setValue(
            isEnglish ? 'chargerLocation' : `translations.${language}.chargerLocation`,
            value,
            {
              shouldDirty: true,
            },
          );
        }}
      />
      <label className="create-section-label create-section-label-bottom-space">
        {'Hourly Charges Url'}
      </label>
      <SpecificCustomSwitchButton
        checked={watchShowHourlyChargesUrl}
        onChange={(value) => {
          setValue("showHourlyChargesUrl", value, {shouldDirty:true})
        }}
      />
      { watchShowHourlyChargesUrl && 
        <InputFieldControl
          name={isEnglish ? 'hourlyChargesUrl' : `translations.${language}.hourlyChargesUrl`}
          title={''}
          rules={{}}
          value={watchHourlyChargesUrl}
          setValue={(value) => {
            setValue(
              isEnglish ? 'hourlyChargesUrl' : `translations.${language}.hourlyChargesUrl`,
              value,
              {
                shouldDirty: true,
              },
            );
          }}
        />
      }
    </>
  )
}
  