import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import { getCarPark, getCarParkList, updateCarPark } from '../services/CarParkAPIHelper';
import { createModel } from './BaseModel';
import { parseTranslations } from './CreateTransactionRecordModel';
import { getDetailPhotos } from './MallModel';
import { loading } from './LoadingUtil';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      orderField: 'name',
    },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
  ],
});

export const getNameByLanguage = (data, language) => {
  const translationsEdges = data?.translations?.edges || [];
  let result = '';
  translationsEdges.forEach((item) => {
    if (language === item.node.language) {
      result = item.node.name;
    }
  });
  return result || data?.name;
};

const parseChargers = (fullChargerChoices, chargerType, chargerChoiceCodes) => {
  return fullChargerChoices?.filter((item)=>item?.chargerType===chargerType).map((item)=>{
    return {
      value: item.code,
      label: item.name,
      selected: !!chargerChoiceCodes.includes(item.code)
    }
  })
}

const parseVendors = (vendorData, fullChargerChoices) => {
  return vendorData?.map((item) => {
    const node = item.node?.vendor ? item.node.vendor : item;
    const chargerChoiceCodes = item.node?.chargerChoices?.edges.map((item)=>{
      return item.node.code
    })
    return {
      ...node,
      vendorVacancyId: item.node.pk,
      showVendorOnApp: item.node?.showVendorOnApp,
      showVendorVacancyOnApp: item.node?.showVendorVacancyOnApp,
      dcChargers: parseChargers(fullChargerChoices, "QUICK_CHARGE", chargerChoiceCodes),
      acChargers: parseChargers(fullChargerChoices, "MEDIUM_CHARGE", chargerChoiceCodes),
    }
  })
}

const parseCarPark = (carpark) => {
  const translationsEdges = carpark.translations?.edges || [];
  const formatPrivileges = parseTranslations(carpark.privileges?.edges);
  const formatPayments = parseTranslations(carpark.payments?.edges);
  const formatedVendors = parseVendors(carpark.vendors?.edges, carpark?.fullChargerChoices);
  const translations = {};
  translations[LanguageConfig.english] = {
    name: carpark.name,
    description: carpark.description,
    address: carpark.address,
    district: carpark.district?.name,
    charges: carpark.charges,
    remarks: carpark.remarks,
    serviceTnc: carpark.serviceTnc,
    carParkPromotion: carpark.carParkPromotion,
    mall: carpark.mall?.name ? `[ID:${carpark.mall?.pk}] ${carpark.mall?.name}` : '-',
    privileges: formatPrivileges?.map((privilege) => {
      return {
        icon: privilege.icon,
        name: privilege.name,
      };
    }),
    payments: formatPayments?.map((payment) => {
      return {
        icon: payment.image,
        name: payment.name,
      };
    }),
    vendors: carpark.vendors?.edges?.map((vendorVacancy) => {
      return {
        name: vendorVacancy?.node?.vendor?.name,
      };
    })
  };
  if (translationsEdges.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      const districtName = getNameByLanguage(carpark.district, language);
      const mallName = getNameByLanguage(carpark.mall, language);
      const privileges = formatPrivileges?.map((privilege) => {
        return {
          icon: privilege.icon,
          name: privilege.translations[language]?.name,
        };
      });
      const payments = formatPayments?.map((payment) => {
        const paymentTranslations = payment?.translations;
        return {
          icon: payment?.image,
          name: paymentTranslations?.[language]?.name,
        };
      });
      const vendors = carpark.vendors?.edges?.map((vendorVacancy) => {
        return {
          name: getNameByLanguage(vendorVacancy?.node?.vendor, language)
        };
      });
      translations[language] = {
        ...item.node,
        id: item.node.pk,
        district: districtName || '-',
        mall: mallName ? `[ID:${carpark.mall?.pk}] ${mallName}` : '-',
        privileges,
        payments,
        vendors,
      };
    });
  }
  return {
    ...carpark,
    coverPhoto: carpark.photo,
    detailPhotos: getDetailPhotos(carpark),
    displayPhoneNumner: carpark.phoneNumber,
    translations,
    chargerLocation: carpark.chargerLocation,
    showHourlyChargesUrl: carpark.showHourlyChargesUrl,
    hourlyChargesUrl: carpark.hourlyChargesUrl,
    formatedVendors: formatedVendors
  };
};

const parseInputBody = (carpark) => {

  const chargerVendor = carpark.formatedVendors.map((vendor)=>{
    let codes = [];
    const pushSelectedCode = (chargers) => {
      chargers.map((charger)=>{
        if (charger.selected) {
          codes.push(charger.value);
        }
      })
    }
    pushSelectedCode(vendor.dcChargers);
    pushSelectedCode(vendor.acChargers);
    return{
      vendorVacancyId: vendor.vendorVacancyId,
      showVendorOnApp: vendor.showVendorOnApp,
      showVendorVacancyOnApp: vendor.showVendorVacancyOnApp,
      chargerChoiceCodes: codes
    }
  })
  let translations = []
  for (const [key, value] of Object.entries(carpark?.translations || [])) {
    if (key === LanguageConfig.english) {
      continue;
    }
    const translationData = {
      chargerLocation: value?.chargerLocation,
      hourlyChargesUrl: value?.hourlyChargesUrl,
      language: key,
    };
    if (value?.id) {
      translationData.id = value?.id;
    }
    translations.push(translationData);
  }
  let inputBody = {
    id: carpark.pk,
    chargerLocation: carpark.chargerLocation,
    showHourlyChargesUrl: carpark.showHourlyChargesUrl,
    hourlyChargesUrl: carpark.hourlyChargesUrl,
    chargerVendor,
    translations: translations
  };

  return inputBody
}

export default createModel({
  namespace: 'carpark',
  states: getInitialState(),
  params: {
    listAPI: getCarParkList,
    parse: (data) =>
      data?.carParks?.edges?.map((item) => parseCarPark(item.node)),
    pkNode: 'CarParkNode',
    detailAPI: getCarPark,
    parseDetail: (data) => parseCarPark(data?.carPark),
    objectKey: 'carParks',
    initState: getInitialState(),
  },
  reducers: {},
  effects: {
    updateCarPark: [
      function* ({ payload }, { put }) {
        const { values } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = parseInputBody(values);
        let serviceArgs = [updateCarPark, input];

        function* onSuccess() {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.success,
              formHasSubmitted: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
