import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import './SelectMultipleStoreV2.scss';
import BaseMultipleSelectorV2 from './BaseMultipleSelectorV2';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import DisplaySelectedData, {
  CustomTitleWithCollapse,
} from './DisplaySelectedData';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomRadios from './CustomRadios';
import SelectMultipleBrand from './SelectMultipleBrand';

const IndividualSelectBrand = ({source, allSelectBrand, onChange}) => {
  const [sourceBrand, setSourceBrand] = useState([]);
  const [selectBrand, setSelectBrand] = useState([]);
  const [searchKey, setSearchKey] = useState([]);
  useEffect(() => {
    if (!searchKey) {
      setSourceBrand(source?.brands);
    }
  }, [source, searchKey])
  useEffect(() => {
    const allSelectBrandPKs = allSelectBrand.map((item) => item.value.pk);
    const result = selectBrand?.filter((item) => allSelectBrandPKs.includes(item.value.pk));
    setSelectBrand(result);
  }, [allSelectBrand])
  return (
    <div style={{borderBottom: '1px dashed #C2C2C2', paddingBottom: '16px'}}>
      <SelectMultipleBrand
        title=""
        filterTitle={source?.name}
        source={sourceBrand}
        value={selectBrand}
        onChange={(value) => {
          setSelectBrand(value);
          onChange(selectBrand || [], value || []);
        }}
        hasMarginTop={false}
        placeholder={'Search by brand'}
        customFilter={(inputValue) => {
          setSearchKey(inputValue);
          if (inputValue) {
            const result = sourceBrand?.filter((brand) =>
              brand.name?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1
              // brand.pk?.toString().indexOf(inputValue?.toLowerCase()) > -1,
            );
            setSourceBrand(result);
          }
        }}
        needLoadMore={false}
      />
    </div>
  );
}

function SelectMultipleStoreV2({
  title = 'title',
  value = [],
  onChange = () => {},
  filterByMall = true,
  disabled = false,
  showLineBrank = false,
  mallList,
  frashMarketList,
  storeCategoryList,
  brandList,
  mallStoreList = [],
  freshMarketStoreList = [],
  storeCategoryL3List = [],
  storeCategoryL2List = [],
  onFocus = () => {},
}) {
  const [filterMall, setFilterMall] = useState([]);
  const [filterFreshMarket, setFilterFreshMarket] = useState([]);
  const [filterStoreCategoryL2, setFilterStoreCategoryL2] = useState([]);
  const [filterStoreCategoryL3, setFilterStoreCategoryL3] = useState([]);
  const [filterBrand, setFilterBrand] = useState([]);
  const [selectBrand, setSelectBrand] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectType, setSelectType] = useState();

  const dispatch = useDispatch();

  const isNotEmpty = (value) => {
    return !(value === undefined || value === null || value.length === 0);
  };

  const getStoreWithBrand = () => {
    if (
      (selectType === 'All' && isNotEmpty(filterBrand)) ||
      (selectType === 'Individual' && isNotEmpty(selectBrand))
    ) {
      dispatch({
        type: 'storeModel/getAllMallStoreList',
        payload: {
          orderBy: 'name',
          isAll: true,
          filterByFreshMarket: !filterByMall,
          mallIn: filterMall?.map((item) => item?.pk),
          freshMarketIn: filterFreshMarket?.map((item) => item?.pk),
          brandIn:
            selectType === 'All'
              ? filterBrand?.map((item) => item?.pk)
              : selectType === 'Individual'
              ? selectBrand?.map((item) => item?.value.pk)
              : [],
          level2CategoryIn: filterStoreCategoryL2?.map((item) => item?.pk),
          level3CategoryIn: filterStoreCategoryL3?.map((item) => item?.pk),
          afterAction: (value) => {
            onChange(value);
          },
        },
      });
    }
  };

  const getBrandWithMallAndStoreGory = () => {
    const filterNode = `${filterByMall ? 'level2Categories' : 'level3Categories'}`;
    if (
      (isNotEmpty(filterMall) && isNotEmpty(filterStoreCategoryL2)) ||
      (isNotEmpty(filterFreshMarket) && isNotEmpty(filterStoreCategoryL3))
    ) {
      dispatch({
        type: 'brand/cleanSelectedBrand',
        payload: {},
      });
      dispatch({
        type: 'brand/getSimpleBrandList',
        payload: {
          isAll: true,
          mallIn: filterMall?.map((item) => item?.pk),
          freshMarketIn: filterFreshMarket?.map((item) => item?.pk),
          level2CategoryIn: filterStoreCategoryL2?.map((item) => item?.pk),
          level3CategoryIn: filterStoreCategoryL3?.map((item) => item?.pk),
          isSimpleFilter: true,
          isSelectorLoad: true,
          sort: 'name',
          filterNode: filterNode,
          afterAction: (value) => {
            const filterStoreCategory = filterByMall ? filterStoreCategoryL2 : filterStoreCategoryL3;
            const newValue = value.map((brand) => {
              const parentKey = filterByMall ? 'level2Categories' : 'level3Categories'
              return {
                ...brand,
                [parentKey]: filterStoreCategory.filter((item) => brand[parentKey].includes(item.pk)),
              }
            })
            setFilterBrand(newValue);
          },
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'mall/getFilterMallList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      },
    });
    dispatch({
      type: 'freshMarket/getList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      },
    });
    dispatch({
      type: 'storeCategoryList/getAllStoreSubcategoryList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      },
    });
    dispatch({
      type: 'createStoreCategoryLv3/getList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
        categoryType: "fresh_market",
      },
    });
  }, [dispatch]);

  const sortByName = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }

    return 0;
  };

  const filterItem = ({
    title,
    value,
    setValue,
    source,
    hideTopSpace,
    namespace,
  }) => (
    <div>
      <BaseMultipleSelectorV2
        title={title}
        namespace={namespace}
        // searchPlaceholder={'Search by name'}
        hideTopSpace={hideTopSpace}
        isToggle={true}
        data={{
          sourceData: source || [],
          targetData: value || [],
          targetChange: (selectedValue) => {
            selectedValue.sort(sortByName);
            setValue(selectedValue);
            setFilterBrand([]);
            setSelectBrand([]);
            onChange([]);
          },
        }}
      />
    </div>
  );

  const selectedBrand = () => {
    const sourceData = [];
    const filterStoreCategory = filterByMall ? filterStoreCategoryL2 : filterStoreCategoryL3;
    const filterStoreCategoryPKs = filterStoreCategory?.map((item) => item?.pk);
    filterBrand.forEach((brand) => {
      brand[filterByMall ? 'level2Categories' : 'level3Categories'].forEach(
        (storeCategory) => {
          if (!filterStoreCategoryPKs.includes(storeCategory.pk)) {
            return;
          }
          const exsitSource = sourceData.find(
            (item) => item.pk === storeCategory.pk,
          );
          if (exsitSource) {
            exsitSource.brands.push(brand);
            return;
          }
          sourceData.push({
            ...storeCategory,
            brands: [brand],
          });
        },
      );
    });
    sourceData.sort(sortByName);
    return (
      <div>
        <CustomTitleLabel
          title={'Select brands of selected store categories'}
        />
        <div style={{ display: 'flex', marginTop: 8 }}>
          <CustomRadios
            options={[
              {
                label: 'All',
                value: 'All',
              },
              {
                label: 'Individual',
                value: 'Individual',
              },
            ]}
            default={selectType}
            onChange={(value) => {
              setSelectType(value);
              getBrandWithMallAndStoreGory();
            }}
          />
        </div>
        {selectType === 'All' ? (
          <DisplaySelectedData
            title={`Selected brand grouped by store category (${filterBrand.length})`}
            data={filterBrand}
            customButton={{
              title: 'Confirm or refresh',
              action: () => {
                getBrandWithMallAndStoreGory();
              },
            }}
            disabled={disabled}
            isToggle={true}
            onChange={(value) => {
              setFilterBrand(value);
              onChange([]);
            }}
            showLabel={(item) => `[ID:${item.pk}] ${item.name}`}
            groupKey={filterByMall ? 'level2Categories' : 'level3Categories'}
            customSort={sortByName}
          />
        ) : null}

        {selectType === 'Individual' ? (
          <CustomTitleWithCollapse
            title={`Selected brand (${selectBrand.length})`}
            customButton={{
              title: 'Confirm or refresh',
              action: () => {
                getBrandWithMallAndStoreGory();
              },
            }}
            isToggle={true}
            customClass={{
              contentClass: 'indicidual-content',
            }}
            body={sourceData.map((item) => 
              <IndividualSelectBrand
                source={item}
                allSelectBrand={selectBrand}
                onChange={(preSelected, value) => {
                  if (preSelected.length < value.length) {
                    const selectBrandPKs = selectBrand.map((item) => item.value.pk);
                    const diffItems = value.filter((item) => !selectBrandPKs.includes(item.value.pk));
                    setSelectBrand([...selectBrand, ...diffItems]);
                    onChange([]);
                  } else if (preSelected.length > value.length) {
                    const valuePKs = value.map((item) => item.value.pk);
                    const diffItems = preSelected.filter((item) => !valuePKs.includes(item.value.pk));
                    const diffPKs = diffItems?.map((item) => item.value.pk);
                    const result = selectBrand.filter((item) => !diffPKs.includes(item.value.pk));
                    setSelectBrand(result);
                    onChange([]);
                  }
                }}
              />
            )}
          />
        ) : null}
      </div>
    );
  };

  const showStoreLabel = (item) => {
    return filterByMall
      ? `[ID:${item.pk}] ${item.name} - ${item.mall?.name} ${item.staffCode}`
      : `[ID:${item.pk}] ${item.name} - ${item.freshMarket?.name} ${item.staffCode}`;
  };

  return (
    <div style={{ marginTop: '30px' }} onFocus={onFocus}>
      {!disabled && showLineBrank ? <div className="line"></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : (
        <>
          <div style={{ marginTop: '8px' }}>
            <SpecificCustomSwitchButton
              show={true}
              checked={showFilter}
              onChange={(value) => {
                setShowFilter(value);
              }}
            />
          </div>
          {!showFilter ? null : (
            <div style={{ marginTop: '-15px' }}>
              <div className="clear-all-filter-container">
                {/* <Button
                  className="clear-filter"
                  onClick={() => {
                    console.log('clear all filter click');
                    setFilterMall();
                    setFilterFreshMarket();
                    setFilterStoreCategoryL2();
                    setFilterStoreCategoryL3();
                    setFilterBrand();
                    setSelectBrand();
                  }}
                >
                  Clear all filter{' '}
                </Button> */}
              </div>
              <div className="filter-item-container">
                {filterByMall
                  ? filterItem({
                      title: 'Filter by mall',
                      source: mallList,
                      value: filterMall,
                      setValue: setFilterMall,
                      hideTopSpace: true,
                      namespace: 'storeModel',
                    })
                  : filterItem({
                      title: 'Filter by fresh market',
                      source: frashMarketList,
                      value: filterFreshMarket,
                      setValue: setFilterFreshMarket,
                      hideTopSpace: true,
                      namespace: 'storeModel',
                    })}
                {filterByMall
                  ? filterItem({
                      title: 'Filter by store category',
                      source: storeCategoryL2List,
                      value: filterStoreCategoryL2,
                      setValue: setFilterStoreCategoryL2,
                      namespace: 'storeCategoryList',
                    })
                  : filterItem({
                      title: 'Filter by store category',
                      source: storeCategoryL3List,
                      value: filterStoreCategoryL3,
                      setValue: setFilterStoreCategoryL3,
                      namespace: 'createStoreCategoryLv3',
                    })}
                {selectedBrand()}
              </div>
            </div>
          )}
        </>
      )}
      <DisplaySelectedData
        title={`Selected store grouped by ${
          filterByMall ? 'mall' : 'fresh market'
        } (${value.length})`}
        data={value}
        customClass={{
          titleClass: 'none-border',
          contentClass: 'dashed-border',
        }}
        customButton={{
          title: 'Confirm or refresh',
          action: () => {
            getStoreWithBrand();
          },
        }}
        disabled={disabled}
        isToggle={false}
        onChange={onChange}
        showLabel={showStoreLabel}
        groupKey={filterByMall ? 'mall' : 'freshMarket'}
        customSort={sortByName}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  mallList: state.mall.filterMallList || [],
  frashMarketList: state.freshMarket.allList || [],
  storeCategoryList: state.storeCategoryList.allList || [],
  brandList: state.brand.brandList || [],
  mallStoreList: state.storeModel.allMallStoreList || [],
  freshMarketStoreList: state.storeModel.allFreshMarketStoreList || [],
  storeCategoryL3List: state.createStoreCategoryLv3.pagedList || [],
  storeCategoryL2List: state.storeCategoryList.subCategoryList || [],
});

export default connect(mapPropsToState)(SelectMultipleStoreV2);
